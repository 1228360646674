<template>
  <div
    class="relative z-10 flex min-h-screen flex-col overflow-hidden overflow-x-hidden bg-plu-black text-white"
  >
    <div class="grow">
      <MenuBar />
      <slot></slot>
    </div>
    <PluFooter></PluFooter>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import MenuBar from "@/Components/menu/MenuBar.vue";
import PluFooter from "@/Components/PluFooter.vue";

export default defineComponent({
  components: { PluFooter, MenuBar },
});
</script>
