<template>
  <section class="bg-white py-20 md:py-36" id="faq-block">
    <InnerConstraint class="relative" v-animate-in>
      <heading4 class="mb-2 md:mb-4">We've got you covered</heading4>
      <heading3 class="mb-16">Frequently asked questions</heading3>
      <faq-item
        v-for="(question, index) in faqQuestions"
        :key="question.question"
        :question="question.question"
        :answers="question.answers"
        :question-number="index + 1"
      ></faq-item>

      <div class="w-full border-b border-plu-black"></div>
    </InnerConstraint>
  </section>
</template>
<script setup>
import InnerConstraint from "@/Components/InnerConstraint.vue";
import Heading4 from "@/Components/texts/Heading4.vue";
import Heading3 from "@/Components/texts/Heading3.vue";
import FaqItem from "@/Components/faq/FaqItem.vue";

const faqQuestions = [
  {
    question: "How often are the meetups organized?",
    answers: ["We aim to organize a meet up three to four times a year."],
  },
  {
    question: "Are the meetups free to attend?",
    answers: [
      "Yes! Thanks to our sponsors, we can keep the meetups accessible to anyone.",
    ],
  },
  {
    question: "What are the meetup talks about?",
    answers: [
      "We like to focus on putting women in the spotlight. Therefore all talks are about the project, initiative or company the speaker is most passionate about. We make sure there's an even balance between speakers from the digital tech and engineering fields.",
    ],
  },
];
</script>
